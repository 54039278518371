class AdvisorServiceClass {
  getAccuracy(accuracy: string): string {
    const acc = +accuracy;
    if (acc % 1 > 0) {
      return acc.toFixed(1);
    }
    return `${acc}`;
  }

  getRating(accuracy: string) {
    return Math.ceil(+accuracy / 20);
  }

  getCheapestFee(advisor: any) {
    const videoFee = +advisor.per_minute_fee_video;
    const audioFee = +advisor.per_minute_fee_audio;
    const textFee = +advisor.per_minute_fee_text;
    return Math.min(videoFee, audioFee, textFee);
  }

  getDiscount(advisor: any) {
    return (advisor?.discount === 'custom' ? advisor.custom_discount : advisor?.discount) || 0;
  }

  getDiscountedPrice(price: number, advisor: any) {
    const discount = this.getDiscount(advisor);
    return price * (1 - (discount / 100));
  }
}

export const AdvisorService = new AdvisorServiceClass();

<template>
  <div v-if="+advisor.accuracy > 0" class="flex relative">
    <!-- accuracy -->
    <div :class="{ 'text-center !pr-40': centered }" class="pr-24">
      <p class="text-h-s font-bold !pb-4">
        {{ accuracy }}%
      </p>
      <p class="text-b-s font-medium text-neutral-50">
        Accuracy
      </p>
    </div>

    <div v-if="divider" class="divider absolute bg-neutral-40 w-[0.5px] h-[72%] top-[14%] left-[50%]" />

    <!-- rating -->
    <div>
      <div :class="{ 'justify-center': centered }" class="flex items-center !pb-4">
        <se-svg name="star-filled" :height="15" :width="15" />
        <p class="text-h-s font-bold !pl-4">
          {{ rating }}
        </p>
      </div>

      <p class="text-b-s font-medium text-neutral-50">
        {{ advisor.total_sessions }} sessions
      </p>
    </div>
  </div>
  <div v-else>
    <div class="pr-24">
      <p class="text-h-s font-bold !pb-4">
        New Advisor
      </p>
      <p class="text-b-s font-medium text-neutral-50">
        No reviews yet
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AdvisorService } from '../../services/advisor.service';
import SeSvg from '../../../../shared/ui-kit/svg/Svg.vue';

const props = withDefaults(defineProps<{
  advisor: any,
  centered?: boolean,
  divider?: boolean,
  clickable?: boolean,
}>(), {
  centered: false,
  divider: false,
  clickable: false,
});

const accuracy = computed(() => AdvisorService.getAccuracy(props.advisor.accuracy));

const rating = computed(() => AdvisorService.getRating(props.advisor.accuracy));
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorAccuracy',
};
</script>
